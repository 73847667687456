<template>
  <b-container class="mb-7">
    <Alert v-if="successMessage" variant="green" icon="check"> {{ successMessage }} </Alert>
    <Alert v-if="errorMessage" variant="red" icon="exclamation">
      {{ errorMessage }}
    </Alert>

    <b-tabs pills vertical content-class="responsive">
      <b-tab title="Organizations" lazy v-if="showAdminOrganizations">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Organizations
            </h1>
          </b-col>
        </b-row>
        <OrganizationsTable />
      </b-tab>
      <b-tab title="Raffles" lazy v-if="showAdminRaffles">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Raffles
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <AdminRaffleList />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Orders" lazy v-if="showAdminOrders">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Orders
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <Orders :isRbAdmin="true" class="orders-page" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Users" lazy v-if="showAdminUsers">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Users
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <UserList :includeAllOrganizations="true" />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Devices" lazy v-if="showAdminDevices">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Devices
            </h1>
          </b-col>
        </b-row>
        <DeviceList @error="handleError" />
      </b-tab>

      <b-tab title="Invoices" lazy v-if="showAdminInvoices">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Invoices
            </h1>
          </b-col>
        </b-row>
        <Invoices :admin="true" />
      </b-tab>
      <b-tab title="Invoices (New)" lazy v-if="showQuickbooksInvoices">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Invoices
            </h1>
          </b-col>
        </b-row>
        <InvoicesQBO :admin="true" />
      </b-tab>
      <b-tab title="Domains" lazy v-if="showManageDomains">
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Domains
            </h1>
          </b-col>
        </b-row>
        <AdminDomainsList />
      </b-tab>
      <b-tab title="Tags" lazy>
        <b-row>
          <b-col>
            <h1>
              <span class="text-muted">Admin ></span>
              Tags
            </h1>
          </b-col>
        </b-row>
        <Tags @onError="handleError" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

import AdminRaffleList from '@/components/AdminRaffleList.vue';
import Invoices from '@/components/Invoices.vue';
import InvoicesQBO from '@/components/InvoicesQBO.vue';
import Tags from '@/pages/admin/Tags';
import Orders from '@/pages/Orders';
import OrganizationsTable from '@/components/OrganizationsTable.vue';
import UserList from '@/components/UserList.vue';
import DeviceList from '@/pages/admin/DeviceList';
import AdminDomainsList from '@/components/AdminDomainsList';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';
import Alert from '@/components/ui/Alert.vue';

export default {
  components: {
    Alert,
    UserList,
    OrganizationsTable,
    AdminRaffleList,
    Invoices,
    Tags,
    Orders,
    DeviceList,
    InvoicesQBO,
    AdminDomainsList
  },
  data() {
    return {
      tagsTableFilter: {
        system: true
      },
      errorMessage: '',
      successMessage: '',
      isRbAdmin: false,
      showQuickbooksInvoices: false,
      showManageDomains: false,
      showAdminInvoices: false,
      showAdminOrganizations: false,
      showAdminOrders: false,
      showAdminUsers: false,
      showAdminRaffles: false,
      showAdminDevices: false
    };
  },
  methods: {
    onCreateTag(event) {
      if (event instanceof Error) {
        this.errorMessage = this.parseError(event).message;
      } else {
        this.successMessage = 'Successfully created tag';
        this.$refs.tagsTable.refreshTable();
      }
    },
    handleError(error) {
      this.errorMessage = this.parseError(error).message;
    }
  },
  async mounted() {
    this.showQuickbooksInvoices = await featureEnabled(LaunchDarklyKeys.QuickbooksInvoices);
    this.showManageDomains = await featureEnabled(LaunchDarklyKeys.ManageDomains);
    this.showAdminInvoices = await featureEnabled(LaunchDarklyKeys.AdminInvoices);
    this.showAdminOrganizations = await featureEnabled(LaunchDarklyKeys.AdminOrganizations);
    this.showAdminOrders = await featureEnabled(LaunchDarklyKeys.AdminOrders);
    this.showAdminUsers = await featureEnabled(LaunchDarklyKeys.AdminUsers);
    this.showAdminRaffles = await featureEnabled(LaunchDarklyKeys.AdminRaffles);
    this.showAdminDevices = await featureEnabled(LaunchDarklyKeys.AdminDevices);
  },
  created: async function () {
    const sessionUser = await getAuth().sessionUser();
    // Render of `<template />` is sync. Therefore we save the role in the component’s data, then render that data.
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 90%;
}

.organization-name {
  font-size: 2.5rem;
  line-height: 1.2;
}

.organization-name + button {
  margin-left: 1em;
}

.title-max-width {
  max-width: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.title-max-width + button {
  margin-top: 5px;
  margin-bottom: auto;
}

.title-max-width:hover {
  max-width: 70%;
  white-space: inherit;
}

a {
  text-decoration: none;
  color: black;
}
.orders-page {
  margin-top: 0px;
  margin-left: -20px;
  max-width: 100%;
}
</style>

<style>
.responsive {
  width: calc(100% - 320px);
}
</style>
