<template>
  <div>
    <b-modal
      :id="modalId"
      v-model="modalShow"
      title="Edit User"
      @show="resetForm"
      @hidden="resetForm"
      @ok="handleOk"
      no-close-on-backdrop
      size="lg"
    >
      <b-form @submit.stop.prevent="onSubmit">
        <div v-if="editUserFailed" class="alert alert-danger">Failed to edit user: {{ errorMessage }}</div>

        <b-form-group label="Organization">
          <organization-select v-if="isRbAdmin" v-model="organizationId" />
        </b-form-group>

        <b-form-group
          label="First Name"
          label-for="input-first-name"
          :invalid-feedback="veeErrors.first('input-first-name')"
        >
          <b-form-input
            name="input-first-name"
            v-model="firstName"
            v-validate="{ required: true, min: 3 }"
            :state="validateState('input-first-name')"
            aria-describedby="input-first-name-feedback"
            data-vv-as="first name"
            trim
          />
        </b-form-group>

        <b-form-group
          label="Last Name"
          label-for="input-last-name"
          :invalid-feedback="veeErrors.first('input-last-name')"
        >
          <b-form-input
            name="input-last-name"
            v-model="lastName"
            v-validate="{ required: true, min: 3 }"
            :state="validateState('input-last-name')"
            data-vv-as="last name"
            trim
          />
        </b-form-group>

        <UserRolesInput :roles="roles" :email="email" @onRoleChange="onRoleChange" />

        <b-form-group
          label="Email"
          label-for="input-email"
          :invalid-feedback="veeErrors.first('input-email')"
          v-if="canEditUserDetails"
        >
          <b-form-input
            name="input-email"
            v-model="email"
            v-validate="{ required: true, email: true }"
            :state="validateState('input-email')"
            aria-describedby="input-email-feedback"
            data-vv-as="email"
            trim
          />
        </b-form-group>

        <b-form-group label="Username" label-for="input-username" :invalid-feedback="veeErrors.first('input-username')">
          <b-form-input
            name="input-username"
            v-model="username"
            v-validate="{ min: 4 }"
            :state="validateState('input-username')"
            aria-describedby="input-username-feedback"
            data-vv-as="username"
            trim
          />
        </b-form-group>

        <b-form-group label="Password" label-for="input-password" :invalid-feedback="veeErrors.first('input-password')">
          <b-form-input
            name="input-password"
            v-model="password"
            type="password"
            v-validate="{ password: true }"
            :state="validateState('input-password')"
            data-vv-as="password"
          />
        </b-form-group>

        <b-form-group
          label="Confirm Password"
          label-for="input-confirm-password"
          :invalid-feedback="veeErrors.first('input-confirm-password')"
        >
          <b-form-input
            autocomplete="new-password"
            name="input-confirm-password"
            v-model="confirmPassword"
            type="password"
            v-validate="{ confirmed: password }"
            :state="validateState('input-confirm-password')"
            data-vv-as="password confirmation"
          />
        </b-form-group>
        <b-form-group label="Disable User" label-for="input-disable-user" label-size="sm" label-class="mb-0">
          <b-form-checkbox
            v-model="disabled"
            :state="validateState('input-disable-user')"
            name="input-disable-user"
            switch
          />
        </b-form-group>
        <b-form-group v-if="isRbAdmin" label="Frozen" label-for="input-frozen-user" label-size="sm" label-class="mb-0">
          <b-form-checkbox
            v-model="frozen"
            :state="validateState('input-frozen-user')"
            name="input-frozen-user"
            switch
          />
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

import UserServiceV2 from '@/lib/user-service-v2';
import OrganizationSelect from './OrganizationSelectV2';

import UserRolesInput from '@/components/forms/UserRolesInput.vue';
import { featureEnabled, LaunchDarklyKeys } from '@/lib/launch-darkly';

export default {
  components: { OrganizationSelect, UserRolesInput },
  props: ['modalId', 'user'],
  data() {
    return {
      modalShow: false,
      editUserFailed: false,
      errorMessage: null,
      id: null,
      userOrgId: null,
      firstName: null,
      lastName: null,
      roles: null,
      email: null,
      username: null,
      activated: null,
      organizationId: null,
      password: null,
      confirmPassword: null,
      disabled: null,
      frozen: null,
      isRbAdmin: false,
      canEditUserDetails: false
    };
  },
  async created() {
    const sessionUser = await getAuth().sessionUser();
    // Render of `<template />` is sync. Therefore we save the role in the component’s data, then render that data.
    this.isRbAdmin = sessionUser.hasRole(Roles.RB_ADMIN);

    this.resetForm();
  },
  async mounted() {
    this.canEditUserDetails = await featureEnabled(LaunchDarklyKeys.EditUserDetails);
  },
  methods: {
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    resetForm() {
      this.editUserFailed = false;
      this.errorMessage = null;
      this.id = this.user.id;
      this.firstName = this.user.firstName;
      this.lastName = this.user.lastName;
      this.roles = this.user.roles;
      this.email = this.user.email;
      this.username = this.user.username;
      this.organizationId = this.user.organizationId;
      this.password = null;
      this.confirmPassword = null;
      this.disabled = this.user.disabled;
      this.frozen = this.user.frozen;

      this.$nextTick(() => {
        this.$validator.reset();
      });
    },

    handleOk(event) {
      event.preventDefault();
      this.onSubmit();
    },

    arrayEquals(a, b) {
      return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
    },

    onRoleChange(updatedRoles) {
      this.roles = updatedRoles;
    },

    async onSubmit() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return;
      }

      const updatedUser = {};

      if (this.firstName !== this.user.firstName) {
        updatedUser.firstName = this.firstName;
      }

      if (this.lastName !== this.user.lastName) {
        updatedUser.lastName = this.lastName;
      }

      if (!this.arrayEquals(this.roles, this.user.roles)) {
        updatedUser.roles = this.roles;
      }

      if (this.email !== this.user.email) {
        updatedUser.email = this.email;
      }

      if (this.username !== this.user.username) {
        updatedUser.username = this.username;
      }

      if (this.organizationId !== this.user.organizationId) {
        updatedUser.organizationId = this.organizationId;
      }

      if (this.password !== null) {
        updatedUser.password = this.password;
      }

      if (this.disabled !== this.user.disabled) {
        updatedUser.disabled = this.disabled;
      }

      if (this.frozen !== this.user.frozen) {
        updatedUser.frozen = this.frozen;
      }

      try {
        await UserServiceV2.updateUser(this.id, updatedUser);

        this.$emit('onEdited', updatedUser);
        this.modalShow = false;
      } catch (error) {
        this.editUserFailed = error;

        if (error.response) {
          this.errorMessage = error.response.data.errors[0].message;
        } else {
          this.errorMessage = 'An unexpected error occurred, please try again later.';
        }
      }
    }
  }
};
</script>

<style scoped></style>
