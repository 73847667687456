<template>
  <div class="flex">
    <FontAwesomeIcon
      class="text-xl h-11 cursor-pointer hover:opacity-80"
      :icon="['far', 'pen-to-square']"
      id="editDeviceButton"
      @click="openModal"
    />

    <div
      v-if="showModal"
      tabindex="-1"
      aria-hidden="true"
      class="
        fixed
        top-0
        left-0
        right-0
        w-full
        p-4
        bg-black bg-opacity-50
        overflow-x-hidden overflow-y-auto
        md:inset-0
        md:h-full
        lift
      "
    >
      <div
        class="
          flex flex-column
          bg-white
          rounded-lg
          max-w-lg
          w-100
          min-height-min
          m-auto
          lift
          divide-y divide-gray-200
          relative
          mt-20
        "
      >
        <div class="p-4 flex flex-row justify-between">
          <h5>Edit Device</h5>
          <a href="#" @click="closeModal" class="text-lg font-bold text-black opacity-50 hover:opacity-100 no-underline"
            >x</a
          >
        </div>
        <div class="flex flex-column p-8" v-if="loading">
          <RbLoadingSpinner class="m-auto" />
        </div>
        <div class="flex flex-column py-4 px-6 gap-4" v-else>
          <Alert v-if="errorMessage" variant="red" icon="exclamation">
            Failed to update the device: {{ errorMessage }}
          </Alert>
          <b-form-group label-for="input-organization-select" v-if="isRbAdmin">
            <template slot="label">Organization <span class="text-danger">*</span></template>
            <OrganizationSelectV2
              inputName="input-organization-select"
              :value="deviceOrganizationId"
              @organizationSelected="organizationSelected"
              :allowEmpty="false"
              ref="multiselect"
            />
          </b-form-group>
          <b-form-group
            id="editDeviceName"
            label-for="input-device-name"
            :invalid-feedback="veeErrors.first('input-device-name')"
          >
            <template slot="label">Device Name <span class="text-danger">*</span></template>
            <b-form-input
              name="input-device-name"
              v-model="deviceName"
              v-validate="{ required: true, min: 5, max: 255 }"
              :state="validateState('input-device-name')"
              :disabled="disableDeviceName"
              aria-describedby="input-device-name-feedback"
              data-vv-as="device name"
              trim
            />
          </b-form-group>
          <b-form-group
            id="editSellerName"
            label-for="input-seller-name"
            :invalid-feedback="veeErrors.first('input-seller-name')"
          >
            <template slot="label">Seller Name</template>
            <b-form-input
              name="input-seller-name"
              v-model="sellerName"
              v-validate="{ max: 255 }"
              :state="validateState('input-seller-name')"
              aria-describedby="input-seller-name-feedback"
              data-vv-as="seller name"
              trim
            />
          </b-form-group>
          <b-form-group label-for="input-serial-number" :invalid-feedback="veeErrors.first('input-serial-number')">
            <template slot="label">Serial Number <span class="text-danger">*</span></template>
            <b-form-input
              name="input-serial-number"
              id="editSerialNumber"
              v-model="serialNumber"
              v-validate="{ required: true, min: 3, max: 255 }"
              :state="validateState('input-serial-number')"
              :disabled="disableSerialNumber"
              aria-describedby="input-serial-number-feedback"
              data-vv-as="serial number"
              trim
            />
          </b-form-group>
          <b-form-group label-for="input-model-name" :invalid-feedback="veeErrors.first('input-model-name')">
            <template slot="label">Model Name <span class="text-danger">*</span></template>
            <b-form-select
              name="input-model-name"
              id="editModelName"
              v-model="modelName"
              v-validate="{ required: true }"
              :state="validateState('input-model-name')"
              :disabled="disableModelName"
              aria-describedby="input-model-name-feedback"
              data-vv-as="model name"
              :options="modelNameOptions"
            />
          </b-form-group>
          <b-form-group
            label-for="input-deployment-group"
            :invalid-feedback="veeErrors.first('input-deployment-group')"
            v-if="isRbAdmin"
          >
            <template slot="label">Deployment Group <span class="text-danger">*</span></template>
            <b-form-select
              name="input-deployment-group"
              v-model="deploymentGroup"
              v-validate="{ required: true }"
              :state="validateState('input-deployment-group')"
              aria-describedby="input-deployment-group-feedback"
              data-vv-as="deployment group"
              :options="deploymentGroupOptions"
            />
          </b-form-group>
          <b-form-group
            label="Active"
            label-for="input-active"
            :invalid-feedback="veeErrors.first('input-active')"
            class="mb-0"
          >
            <b-form-checkbox
              switch
              name="input-active"
              v-model="active"
              :state="validateState('input-active')"
              data-vv-as="active"
            />
          </b-form-group>
        </div>
        <div class="px-2 py-3 mt-4 flex flex-row justify-end">
          <LinkButton variant="default" @click.native="closeModal" class="h-11 mr-4 whitespace-nowrap"
            >Cancel</LinkButton
          >
          <LinkButton variant="blue" @click.native="submit" id="submitButton" class="h-11 mr-4 whitespace-nowrap">
            Save
          </LinkButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from '@/components/ui/LinkButton';
import Alert from '@/components/ui/Alert';
import OrganizationSelectV2 from '@/components/OrganizationSelectV2';
import { getAuth, Roles } from '@rafflebox-technologies-inc/auth-service-sdk';

export default {
  props: {
    deviceId: {
      type: String,
      required: true
    }
  },
  components: {
    Alert,
    LinkButton,
    OrganizationSelectV2
  },
  data() {
    return {
      sessionUser: null,
      deviceOrganizationId: null,
      organization: null,
      deviceName: null,
      sellerName: null,
      serialNumber: null,
      modelName: null,
      deploymentGroup: null,
      active: null,
      modelNameOptions: ['PAX A920', 'PAX A920 Pro', 'SUNMI T2 Mini', 'SUNMI V2', 'Unknown'],
      deploymentGroupOptions: ['iv-blue-us', 'iv-green-us'],
      isRbAdmin: false,
      showModal: false
    };
  },
  computed: {
    loading() {
      return this.$store.state.modals.loading;
    },
    errorMessage() {
      return this.$store.state.modals.error;
    },
    disableDeviceName() {
      return !this.sessionUser.hasRole(Roles.ACCOUNT_OWNER) && !this.isRbAdmin;
    },
    disableSerialNumber() {
      return !this.isRbAdmin;
    },
    disableModelName() {
      return !this.isRbAdmin;
    }
  },
  async mounted() {
    this.sessionUser = await getAuth().sessionUser();
    this.isRbAdmin = this.sessionUser.hasRole(Roles.RB_ADMIN);
  },
  methods: {
    async openModal() {
      this.showModal = true;
      await this.loadDevice();
    },
    closeModal() {
      this.showModal = false;
    },
    async loadDevice() {
      await this.$store.dispatch('devices/readDevice', this.deviceId);
      const device = this.$store.state.devices.device;

      this.deviceOrganizationId = device.organizationId;
      this.deviceName = device.name;
      this.sellerName = device.sellerName;
      this.serialNumber = device.serialNumber;
      this.active = device.active;
      this.modelName = device.info.modelName;
      this.deploymentGroup = device.deploymentGroup;

      this.$store.dispatch('stopModalLoading');
    },
    async submit() {
      const valid = await this.$validator.validateAll();

      if (!valid) {
        return;
      }

      const params = {
        deviceId: this.deviceId,
        body: {
          organizationId: this.organization?.id ? this.organization.id : this.deviceOrganizationId,
          name: this.deviceName,
          serialNumber: this.serialNumber,
          active: this.active,
          info: {
            modelName: this.modelName
          },
          deploymentGroup: this.deploymentGroup,
          sellerName: this.sellerName || null
        },
        isModal: true
      };

      if (!this.isRbAdmin) {
        delete params.body.organizationId;
        delete params.body.serialNumber;
        delete params.body.info;
        delete params.body.deploymentGroup;

        if (!this.sessionUser.hasRole(Roles.ACCOUNT_OWNER)) {
          delete params.body.name;
        }
      }
      this.showModal = false;

      await this.$store.dispatch('devices/updateDevice', params);
      this.$emit('deviceUpdated');
    },
    organizationSelected(organization) {
      this.organization = organization;
    },
    validateState(ref) {
      if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
        return !this.veeErrors.has(ref);
      }
      return null;
    }
  }
};
</script>

<style>
.lift {
  z-index: 1050;
}

.min-width {
  min-width: 32rem;
}
</style>
