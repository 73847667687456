<template>
  <div>
    <b-alert v-model="success.model" variant="success" dismissible fade>
      {{ success.text }}
    </b-alert>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <b-row class="container-search">
      <b-col sm="4">
        <b-form-input v-model="search" placeholder="Enter search" debounce="300"></b-form-input>
      </b-col>
    </b-row>
    <GenericTable
      :columns="columns"
      :data="data"
      :pagination="pagination"
      :loading="loading"
      :actions="true"
      @sort="sort"
      @updateCurrentPage="updateCurrentPage"
      class="raffles mt-4"
    >
      <template #endDate="{ row }">
        {{ formatDateTime(parseISO(row.endDate)) }}
      </template>
      <template #name="{ row }">
        <router-link :to="{ path: `raffle?id=${row.id}` }" class="p-2 text-gray-800 button-link underline">
          {{ row.name }}
        </router-link>
      </template>
      <template #organizationName="{ row }">
        <router-link
          :to="{ path: `/admin/organization?id=${row.organizationId}` }"
          class="p-2 text-gray-800 button-link underline"
        >
          {{ row.organizationName }}
        </router-link>
      </template>
      <template #status="{ row }">
        <EventStatusBadge :status="row.status" />
      </template>
      <template #shortlink="{ row }">
        <a :href="raffleboxUrl + '/raffle/' + row.shortlink" target="top" class="text-gray-800 underline">
          {{ row.shortlink }}
        </a>
      </template>
      <template #actions="{ row }">
        <span v-b-modal="row.id"><i class="far fa-edit" /></span>
        <MoveRaffleModal @onEdited="onEditRaffle" :modalId="row.id" :raffle="row" />
      </template>
    </GenericTable>
  </div>
</template>

<script>
import EventService from '@/lib/event-service-v2';
import GenericTable from '@/components/GenericTable.vue';
import MoveRaffleModal from '@/components/MoveRaffleModal.vue';
import EventStatusBadge from '@/components/EventStatusBadge';
import config from '@/config';

export default {
  name: 'AdminRaffleList',
  components: { GenericTable, MoveRaffleModal, EventStatusBadge },
  watch: {
    search: function () {
      this.refreshEvents();
    }
  },
  data() {
    return {
      search: '',
      raffleboxUrl: config.RAFFLEBOX_URL,
      alert: {
        text: '',
        model: false
      },
      success: {
        text: '',
        model: false
      },
      loading: false,
      columns: [
        {
          name: 'number',
          label: 'Number',
          classes: 'w-auto'
        },
        {
          name: 'endDate',
          label: 'End Date',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'name',
          label: 'Name',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'organizationName',
          label: 'Organization Name',
          classes: 'w-auto'
        },
        {
          name: 'status',
          label: 'Status',
          classes: 'w-auto'
        },
        {
          name: 'shortlink',
          label: 'Shortlink',
          sortable: true,
          classes: 'w-auto'
        }
      ],
      data: [],
      pagination: {
        page: 0,
        pageSize: 10,
        sortBy: 'id',
        sortDir: 'desc',
        total: 0
      }
    };
  },
  async mounted() {
    await this.getEvents();
  },
  methods: {
    async refreshEvents() {
      await this.getEvents();
    },
    handleSuccess(text) {
      this.success.text = text;
      this.success.model = true;
      this.refreshEvents();
    },
    handleError(error) {
      this.alert.text = error.response ? error.response.data.errors[0].message : error;
      this.alert.model = true;
    },
    updateCurrentPage(page) {
      this.pagination.page = page;
      this.refreshEvents();
    },
    sort(sortBy, sortDir) {
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      this.refreshEvents();
    },
    async getEvents() {
      this.loading = true;

      const params = {
        page: this.pagination.page,
        pageSize: this.pagination.pageSize,
        sortBy: this.pagination.sortBy || undefined,
        sortDir: this.pagination.sortDir,
        search: this.search ? this.search : undefined,
        allEvents: true
      };
      try {
        const result = await EventService.listEvents(params);

        this.pagination = result.pagination;
        this.data = result.data;
        this.loading = false;
      } catch (error) {
        this.handleError(error);
        return [];
      }
    },
    onEditRaffle() {
      this.refreshEvents();
    }
  }
};
</script>

<style scoped></style>
