<template>
  <GenericTable
    :columns="columns"
    :data="data"
    :pagination="pagination"
    :loading="loading"
    :actions="true"
    :allowSelect="true"
    @sort="sort"
    @selectedRows="selectedRows"
    @updateCurrentPage="updateCurrentPage"
    class="devices"
  >
    <template #name="{ row }">
      <router-link :to="{ path: `device/${row.id}` }" class="p-2 text-gray-800 button-link underline">
        {{ row.name }}
      </router-link>
    </template>
    <template #active="{ row }">
      <StatusBadge :status="row.active" />
    </template>
    <template #actions="{ row }">
      <EditDeviceModal @deviceUpdated="deviceUpdated()" v-if="row.id" :deviceId="row.id" class="ml-4" />
      <span class="sr-only">Edit Device</span>
    </template>
  </GenericTable>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import StatusBadge from '@/components/ui/StatusBadge';
import EditDeviceModal from '@/components/modals/EditDeviceModal';
import DeviceServiceV2 from '@/lib/device-service-v2';
import { debounce } from 'lodash';

export default {
  components: {
    GenericTable,
    EditDeviceModal,
    StatusBadge
  },
  props: {
    organization: {
      type: Object,
      required: false
    },
    search: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'name',
          label: 'Device name',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'organizationName',
          label: 'Organization',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'serialNumber',
          label: 'Serial Number',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'sellerName',
          label: 'Seller Name',
          sortable: true,
          classes: 'w-auto'
        },
        {
          name: 'active',
          label: 'Status',
          sortable: true,
          classes: 'w-auto'
        }
      ],
      title: 'Devices',
      data: [],
      selectedDevices: {},
      sortBy: 'name',
      sortDir: 'asc',
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0
      },
      loading: false
    };
  },
  async beforeMount() {
    await this.getDevices();
  },
  watch: {
    organization() {
      this.getDevices();
    },
    search: debounce(async function () {
      await this.updateCurrentPage(0);
      await this.getDevices();
    }, 500)
  },
  methods: {
    async getDevices() {
      this.loading = true;

      try {
        const params = {
          search: this.search,
          page: this.currentPage,
          pageSize: this.pageSize,
          sortBy: this.sortBy,
          sortDir: this.sortDir
        };

        if (this.organization) {
          params.organizationId = this.organization.id;
        }

        const { data, pagination } = await DeviceServiceV2.listDevices(params);
        this.data = data;
        this.pagination = pagination;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$emit('error', error);
      }
    },
    async deviceUpdated() {
      await this.getDevices();
    },
    async sort(sortBy, sortDir) {
      this.sortBy = sortBy;
      this.sortDir = sortDir;
      await this.getDevices();
    },
    selectedRows(rows) {
      this.selectedDevices = rows;
      this.$emit('updateSelectedDevices', rows);
    },
    async updateCurrentPage(page) {
      this.currentPage = page;
      await this.getDevices();
    }
  }
};
</script>
