<template>
  <GenericModal :title="getTitle" size="sm" :config="modalConfig">
    <template #openButton="{ openModal }">
      <LoadingButton
        @click.native="openModal"
        variant="outline"
        :disabled="devices.length === 0"
        class="h-11 mr-4 whitespace-nowrap"
      >
        Move Devices {{ devices.length > 0 ? `(${devices.length})` : '' }}
      </LoadingButton>
    </template>
    <template>
      <div class="p-2">
        <p class="mb-4 text-base">
          Choose the organization you wish to move the
          <strong v-if="devices">{{ devices.length }}</strong>
          selected devices to below.
        </p>
        <p class="mb-4">
          This will remove all users from the device and Ticket Sellers in the new organization will be allowed to sell
          from these devices.
        </p>

        <div class="relative">
          <OrganizationSelectV2
            inputName="input-organization-select"
            @organizationSelected="organizationSelected"
            :allowEmpty="false"
            ref="multiselect"
          />
        </div>
      </div>
    </template>
    <template slot="footer" slot-scope="{ closeModal }">
      <LoadingButton variant="green" @click.native="submit(closeModal)">Save</LoadingButton>
    </template>
  </GenericModal>
</template>

<script>
import GenericModal from '@/components/modals/GenericModal.vue';
import LoadingButton from '@/components/ui/LoadingButton.vue';
import DeviceServiceV2 from '@/lib/device-service-v2';
import OrganizationSelectV2 from '@/components/OrganizationSelectV2';

export default {
  components: {
    GenericModal,
    LoadingButton,
    OrganizationSelectV2
  },
  props: {
    devices: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      modalConfig: {
        showCloseModalButton: false
      },
      amount: null,
      title: 'Move Devices'
    };
  },
  computed: {
    getTitle() {
      return `${this.title}`;
    }
  },
  methods: {
    organizationSelected(organization) {
      this.organization = organization;
    },
    async submit(closeModal) {
      try {
        const { id } = this.organization;

        await DeviceServiceV2.bulkMoveDevices(this.devices, id);

        this.organization = null;
        closeModal();
        this.$emit('triggerRefresh');
      } catch (error) {
        this.errorMessage = this.parseError(error).message;
      }
    }
  }
};
</script>

<style scoped>
.dropdown {
  z-index: 1002 !important;
}
</style>
