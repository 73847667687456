<template>
  <div>
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 mt-4">
        <div class="flex flex-wrap xl:flex-nowrap justify-start items-start search-wrap">
          <div class="flex items-start max-w-xl mb-2 pr-2 search relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 h-10">
              <FontAwesomeIcon class="h-5 w-5 text-gray-400 text-xl" :icon="['far', 'magnifying-glass']" />
            </div>
            <input
              v-model="currentSearch"
              class="block w-75 rounded-md border h-11 pl-10"
              type="search"
              placeholder="Enter search"
            />

            <div class="max-w-lg w-80 ml-6">
              <OrganizationSelectV2
                @organizationSelected="organizationSelected"
                placeholderText="Select Organization"
              />
            </div>
          </div>

          <LinkButton variant="outline" v-b-modal="'add-device-modal'" class="h-11 ml-4 mr-4 whitespace-nowrap">
            Add Device
          </LinkButton>
          <AddDeviceModal />
          <MoveDevicesModal :devices="selectedDevices" @triggerRefresh="triggerRefresh" />
        </div>

        <AdminDeviceTable
          ref="adminDeviceTable"
          :organization="organization"
          :search="currentSearch"
          :pagination="pagination"
          @updateSelectedDevices="updateSelectedDevices"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LinkButton from '@/components/ui/LinkButton';
import AddDeviceModal from '@/components/modals/AddDeviceModal';
import OrganizationSelectV2 from '@/components/OrganizationSelectV2';
import AdminDeviceTable from '@/components/ui/AdminDeviceTable';
import MoveDevicesModal from '@/components/modals/MoveDevicesModal';

export default {
  name: 'DeviceList',
  components: {
    LinkButton,
    AddDeviceModal,
    MoveDevicesModal,
    OrganizationSelectV2,
    AdminDeviceTable
  },
  data() {
    return {
      devices: [],
      currentSearch: null,
      organization: null,
      error: null,
      selectedDevices: [],
      pagination: {
        page: 0,
        pageSize: 10,
        total: 0
      }
    };
  },
  methods: {
    organizationSelected(organization) {
      this.organization = organization;
    },
    updateSelectedDevices(devices) {
      this.selectedDevices = devices;
    },
    triggerRefresh() {
      this.$refs.adminDeviceTable.getDevices();
    }
  },
  watch: {
    error: function () {
      this.$emit('error', this.error);
    }
  }
};
</script>

<style>
.search-wrap {
  padding-bottom: 1rem;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
</style>
