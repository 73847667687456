<template>
  <GenericTable
    :columns="columns"
    :data="data"
    :loadMore="true"
    :loading="loading"
    @loadMoreData="loadMoreData"
    :cannotLoadMore="!nextMarker"
    totalName="Displayed Domains"
    class="domains-table"
  >
    <template #Name="{ row }">
      <a :href="'http://www.' + row.Name.slice(0, -1)" target="_blank" class="text-gray-800 button-link underline">
        {{ row.Name.slice(0, -1) }}
      </a>
    </template>
    <template #Organization="{ row }">
      <router-link
        v-if="row.Organization"
        :to="{ path: `admin/organization?id=${row.Comment}` }"
        class="text-gray-800 button-link underline"
      >
        {{ row.Organization }}
      </router-link>
      <p v-else :class="!row.Organization && 'text-gray-400'">Unassigned</p>
    </template>
    <template #CreationDate="{ row }">
      <p :id="`domain-${row.Id}-1`" class="dotted-underline">
        {{ row.CreationDate ? format(new Date(row.CreationDate), 'yyyy-MM-dd') : '' }}
      </p>
      <b-tooltip :target="`domain-${row.Id}-1`" triggers="hover" v-if="row.CreationDate">
        {{
          formatDateTime(parseISO(row.CreationDate), {
            format: 'dateTime',
            timeZone: sessionUserTimeZone,
            displayTimeZone: true
          })
        }}
      </b-tooltip>
    </template>
    <template #ExpirationDate="{ row }">
      <p :id="`domain-${row.Id}-2`" class="dotted-underline">
        {{ row.ExpirationDate ? format(new Date(row.ExpirationDate), 'yyyy-MM-dd') : '' }}
      </p>
      <b-tooltip :target="`domain-${row.Id}-2`" triggers="hover" v-if="row.ExpirationDate">
        {{
          formatDateTime(parseISO(row.ExpirationDate), {
            format: 'dateTime',
            timeZone: sessionUserTimeZone,
            displayTimeZone: true
          })
        }}
      </b-tooltip>
    </template>
    <template #AutoRenew="{ row }">
      <RbBadge :variant="row.AutoRenew ? 'success' : 'danger'" :label="row.AutoRenew ? 'Yes' : 'No'" />
    </template>
  </GenericTable>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import DomainService from '@/lib/domain-service-v2';
import { format } from 'date-fns';

export default {
  components: {
    GenericTable
  },
  props: {},
  data() {
    return {
      columns: [
        {
          name: 'Name',
          label: 'Domain Name',
          classes: 'w-auto'
        },
        {
          name: 'Organization',
          label: 'Organization',
          classes: 'w-auto'
        },
        {
          name: 'CreationDate',
          label: 'Created',
          classes: 'w-auto'
        },
        {
          name: 'ExpirationDate',
          label: 'Expires',
          classes: 'w-auto'
        },
        {
          name: 'AutoRenew',
          label: 'Auto Renewal',
          classes: 'w-auto'
        }
      ],
      title: 'Domains',
      data: [],
      sortBy: 'Name',
      sortDir: 'asc',
      loading: false,
      format: format,
      nextMarker: null
    };
  },
  async beforeMount() {
    this.loading = true;
    await this.getDomains();
    this.loading = false;
  },
  methods: {
    async getDomains() {
      const params = {
        marker: this.nextMarker
      };

      try {
        const result = await DomainService.listDomains(params);

        this.data = [...this.data, ...result.data.hostedZones];

        if (result.data.nextMarker) {
          this.nextMarker = result.data.nextMarker;
        } else {
          this.nextMarker = null;
        }
      } catch (error) {
        this.loading = false;
      }
    },
    async loadMoreData(callback) {
      await this.getDomains();
      callback();
    },
    calculateDifference(date) {
      if (!date) return;
      const today = new Date();
      const expirationDate = new Date(date);

      const differenceInTime = expirationDate.getTime() - today.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      if (differenceInDays < 0) {
        return 'text-red-600';
      }
      if (differenceInDays < 30) {
        return 'text-yellow-600';
      }
      return;
    }
  }
};
</script>
<style lang="scss">
.dotted-underline {
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: pointer;
}
</style>
