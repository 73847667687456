<template>
  <div class="d-flex justify-content-end">
    <b-dropdown variant="outline-secondary" class="action-button" toggle-class="border-0" no-caret right size="sm">
      <template #button-content>
        <slot name="button-content">
          <em class="fas fa-ellipsis-v" />
        </slot>
      </template>
      <slot></slot>
    </b-dropdown>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  }
};
</script>
<style scoped>
.action-button {
  width: 1.7em;
}
</style>
