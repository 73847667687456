var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"success","dismissible":"","fade":""},model:{value:(_vm.success.model),callback:function ($$v) {_vm.$set(_vm.success, "model", $$v)},expression:"success.model"}},[_vm._v(" "+_vm._s(_vm.success.text)+" ")]),_c('b-alert',{attrs:{"variant":"danger","dismissible":"","fade":""},model:{value:(_vm.alert.model),callback:function ($$v) {_vm.$set(_vm.alert, "model", $$v)},expression:"alert.model"}},[_vm._v(" "+_vm._s(_vm.alert.text)+" ")]),_c('b-row',{staticClass:"container-search"},[_c('b-col',{attrs:{"sm":"4"}},[_c('b-form-input',{attrs:{"placeholder":"Enter search","debounce":"300"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('GenericTable',{staticClass:"raffles mt-4",attrs:{"columns":_vm.columns,"data":_vm.data,"pagination":_vm.pagination,"loading":_vm.loading,"actions":true},on:{"sort":_vm.sort,"updateCurrentPage":_vm.updateCurrentPage},scopedSlots:_vm._u([{key:"endDate",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.parseISO(row.endDate)))+" ")]}},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"p-2 text-gray-800 button-link underline",attrs:{"to":{ path: ("raffle?id=" + (row.id)) }}},[_vm._v(" "+_vm._s(row.name)+" ")])]}},{key:"organizationName",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"p-2 text-gray-800 button-link underline",attrs:{"to":{ path: ("/admin/organization?id=" + (row.organizationId)) }}},[_vm._v(" "+_vm._s(row.organizationName)+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('EventStatusBadge',{attrs:{"status":row.status}})]}},{key:"shortlink",fn:function(ref){
var row = ref.row;
return [_c('a',{staticClass:"text-gray-800 underline",attrs:{"href":_vm.raffleboxUrl + '/raffle/' + row.shortlink,"target":"top"}},[_vm._v(" "+_vm._s(row.shortlink)+" ")])]}},{key:"actions",fn:function(ref){
var row = ref.row;
return [_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(row.id),expression:"row.id"}]},[_c('i',{staticClass:"far fa-edit"})]),_c('MoveRaffleModal',{attrs:{"modalId":row.id,"raffle":row},on:{"onEdited":_vm.onEditRaffle}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }